import { Box, Button, FormControl, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { useDispatchWrapper } from "../../../hooks";

export const ValidateOtpAndResetPassword: React.FC<{ email: string }> = ({
  email,
}) => {
  const { resetPasswordAsync } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik({
      initialValues: {
        email: email,
        password: "",
        emailOTP: "",
      },
      validate: (values) => {
        const errors: any = {};
        if (!values.emailOTP) {
          errors.emailOTP = "*OTP is required.";
        }
        if (!values.email) {
          errors.password = "*Password is required.";
        }
        return errors;
      },
      onSubmit: (values) => {
        setLoading(true);
        resetPasswordAsync(
          values.email,
          values.password,
          Number(values.emailOTP),
          () => {
            setLoading(false);
            navigate("/dashboard");
          },
          (errorMessage) => {
            setLoading(false);
            setFieldError("emailOTP", errorMessage);
          },
        );
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        sx={{ mt: 2 }}
        fullWidth
        error={errors.emailOTP ? true : false}
      >
        <Box display="flex" justifyContent={"end"}>
          <Typography
            fontWeight="500"
            sx={{
              display: "block",
              textDecoration: "none",
              mt: 2,
            }}
          >
            We’ve Successfully sent a One Time Password (OTP) to the email.
            Please enter otp and new password to reset.
          </Typography>
        </Box>
        <CustomFormLabel>OTP</CustomFormLabel>
        <CustomTextField
          id="emailOTP"
          variant="outlined"
          value={values.emailOTP}
          fullWidth
          type="number"
          error={errors.emailOTP ? true : false}
          helperText={errors.emailOTP}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <CustomFormLabel>New Password</CustomFormLabel>
        <CustomTextField
          id="password"
          variant="outlined"
          value={values.password}
          type="password"
          fullWidth
          error={errors.password ? true : false}
          helperText={errors.password}
          onChange={handleChange}
        />
      </FormControl>

      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        disabled={loading}
        type="submit"
        sx={{
          mt: 4,
          pt: "10px",
          pb: "10px",
        }}
      >
        Change Password
      </Button>
    </form>
  );
};
