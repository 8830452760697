import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IPurchaseOrder } from "./purchase-order.types";
import {
  SERVER_GET_PURCHASE_ORDER_ROUTE,
  SERVER_UPSERT_PURCHASE_ORDER_ROUTE,
} from "./purchase-order.routes";

export const FETCH_PURCHASE_ORDER_LIST_PROGRESS =
  "FETCH_PURCHASE_ORDER_LIST_PROGRESS";
export const FETCH_PURCHASE_ORDER_LIST_SUCCESS =
  "FETCH_PURCHASE_ORDER_LIST_SUCCESS";
export const FETCH_PURCHASE_ORDER_LIST_FAILED =
  "FETCH_PURCHASE_ORDER_LIST_FAILED";

export const fetchPurchaseOrderListProgress = () =>
  action(FETCH_PURCHASE_ORDER_LIST_PROGRESS);

export const fetchPurchaseOrderListSuccess = (
  data: IPurchaseOrder[],
  totalRecords: number,
) => action(FETCH_PURCHASE_ORDER_LIST_SUCCESS, { data, totalRecords });
export const fetchPurchaseOrderListFailed = () =>
  action(FETCH_PURCHASE_ORDER_LIST_FAILED);

export const fetchPurchaseOrderListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchPurchaseOrderListProgress());
      const res = await api.get(
        `${SERVER_GET_PURCHASE_ORDER_ROUTE}${searchQuery}`,
      );
      const data: IPurchaseOrder[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPurchaseOrderListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPurchaseOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_PURCHASE_ORDER_PROGRESS = "FETCH_PURCHASE_ORDER_PROGRESS";
export const FETCH_PURCHASE_ORDER_SUCCESS = "FETCH_PURCHASE_ORDER_SUCCESS";
export const FETCH_PURCHASE_ORDER_FAILED = "FETCH_PURCHASE_ORDER_FAILED";

export const fetchPurchaseOrderProgress = () =>
  action(FETCH_PURCHASE_ORDER_PROGRESS);
export const fetchPurchaseOrderSuccess = (data: IPurchaseOrder) =>
  action(FETCH_PURCHASE_ORDER_SUCCESS, { data });
export const fetchPurchaseOrderFailed = (errorMessage: string) =>
  action(FETCH_PURCHASE_ORDER_FAILED, { errorMessage });

export const fetchSinglePurchaseOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPurchaseOrderProgress());
      const res = await api.get(
        `${SERVER_GET_PURCHASE_ORDER_ROUTE}?vendors_uuid=${uuid}`,
      );
      const data: IPurchaseOrder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchPurchaseOrderSuccess(data[0]));
      } else {
        dispatch(
          fetchPurchaseOrderFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchPurchaseOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSinglePurchaseOrderAsync =
  (
    data: IPurchaseOrder,
    onCallback: (isSuccess: boolean, deviation?: IPurchaseOrder) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_PURCHASE_ORDER_ROUTE, payload);
      let message = "MRP Allocation saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_PURCHASE_ORDER = "CLEAR_PURCHASE_ORDER";
export const CLEAR_PURCHASE_ORDER_STATE = "CLEAR_PURCHASE_ORDER_STATE";
export const clearSinglePurchaseOrderSync = () => action(CLEAR_PURCHASE_ORDER);
export const clearPurchaseOrdersStateSync = () =>
  action(CLEAR_PURCHASE_ORDER_STATE);
