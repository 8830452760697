import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { IEnquiryQRF } from "./enquiry-qrf.types";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_SINGLE_ENQUIRY_QRF_PROGRESS =
  "FETCH_SINGLE_ENQUIRY_QRF_PROGRESS";
export const FETCH_SINGLE_ENQUIRY_QRF_SUCCESS =
  "FETCH_SINGLE_ENQUIRY_QRF_SUCCESS";
export const FETCH_SINGLE_ENQUIRY_QRF_FAILED =
  "FETCH_SINGLE_ENQUIRY_QRF_FAILED";

export const fetchSingleEnquiryQrfProgress = () =>
  action(FETCH_SINGLE_ENQUIRY_QRF_PROGRESS);
export const fetchSingleEnquiryQrfSuccess = (data: IEnquiryQRF) =>
  action(FETCH_SINGLE_ENQUIRY_QRF_SUCCESS, { data });
export const fetchSingleEnquiryQrfFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ENQUIRY_QRF_FAILED, { errorMessage });

export const fetchSingleEnquiryQrfAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryQrfProgress());
      const res = await api.get(`/enquiry/get-qrf?qrf_uuid=${uuid}`);
      const data: IEnquiryQRF[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryQrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryQrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryQrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchSingleEnquiryQrfByEnquiryNumberAsync =
  (enquiry_no: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryQrfProgress());
      const res = await api.get(`/enquiry/get-qrf?enquiry_no=${enquiry_no}`);
      const data: IEnquiryQRF[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryQrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryQrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryQrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertEnquiryQRFAsync =
  (
    qrfData: IEnquiryQRF,
    requestStatus: "QRF" | "QUOTE",
    onCallback: (isSuccess: boolean, orfInfo?: IEnquiryQRF) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const { create_ts, insert_ts, ...payload } = qrfData;
      const payload = {
        qrf_uuid: qrfData.qrf_uuid,
        enquiry_no: qrfData.enquiry_no,
        enquiry_uuid: qrfData.enquiry_uuid,
        customer_uuid: qrfData.customer_uuid,
        customer_name: qrfData.customer_name,
        no_of_options_required: qrfData.no_of_options_required,
        condition_of_delivery: qrfData.condition_of_delivery,
        sales_rep_uuid: qrfData.sales_rep_uuid,
        sales_rep: qrfData.sales_rep,
        contact_uuid: qrfData.contact_uuid,
        contact_name: qrfData.contact_name,
        condition_of_payment: qrfData.condition_of_payment,
        product_items: qrfData.product_items,
        attachments: qrfData.attachments,
        issued_by: qrfData.issued_by,
        checked_by: qrfData.checked_by,
        remark: qrfData.remark,
        notes: qrfData.notes,
        request_status: requestStatus,
      };

      dispatch(saveLoaderProgress());
      const res = await api.post(`/enquiry/upsert-qrf`, payload);
      let message = "QRF saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const upsertuoteItemFomQrfAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const { create_ts, insert_ts, ...payload } = qrfData;
      dispatch(saveLoaderProgress());
      await api.post("/quotes/update-quote-items-from-qrf", { qrf_uuid: uuid });
      let message = "Action saved successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// upsert reviser save update qrf
export const upsertReviseSaveQRFAsync =
  (
    qrfData: IEnquiryQRF,
    onCallback: (isSuccess: boolean, orfInfo?: IEnquiryQRF) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const payload = {
        qrf_uuid: qrfData.qrf_uuid,
        enquiry_no: qrfData.enquiry_no,
        enquiry_uuid: qrfData.enquiry_uuid,
        customer_uuid: qrfData.customer_uuid,
        customer_name: qrfData.customer_name,
        no_of_options_required: qrfData.no_of_options_required,
        condition_of_delivery: qrfData.condition_of_delivery,
        sales_rep_uuid: qrfData.sales_rep_uuid,
        sales_rep: qrfData.sales_rep,
        contact_uuid: qrfData.contact_uuid,
        contact_name: qrfData.contact_name,
        condition_of_payment: qrfData.condition_of_payment,
        product_items: qrfData.product_items,
        attachments: qrfData.attachments,
        issued_by: qrfData.issued_by,
        checked_by: qrfData.checked_by,
        remark: qrfData.remark,
        notes: qrfData.notes,
      };

      dispatch(saveLoaderProgress());
      const res = await api.post(`/quotes/revise-save-update-qrf`, payload);
      let message = "QRF saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_ENQUIRY_QRF = "CLEAR_SINGLE_ENQUIRY_QRF";
export const clearSingleEnquiryQrf = () => action(CLEAR_SINGLE_ENQUIRY_QRF);
