import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_PURCHASE_ORDER_ROUTE } from "./PurchaseOrder.constants";

const PurchaseOrderListTable = Loadable(
  lazy(() =>
    import("./PurchaseOrderListTable").then(({ PurchaseOrderListTable }) => ({
      default: PurchaseOrderListTable,
    })),
  ),
);
const ManageSinglePurchaseOrder = Loadable(
  lazy(() =>
    import("./ManageSinglePurchaseOrder").then(
      ({ ManageSinglePurchaseOrder }) => ({
        default: ManageSinglePurchaseOrder,
      }),
    ),
  ),
);

export const purchaseOrdersRoutes = [
  {
    path: APP_PURCHASE_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <PurchaseOrderListTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PURCHASE_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSinglePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PURCHASE_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSinglePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
];
