import produce from "immer";

import {
  CLEAR_PART_ITEM,
  CLEAR_PART_ITEM_STATE,
  FETCH_PART_ITEM_LIST_FAILED,
  FETCH_PART_ITEM_LIST_PROGRESS,
  FETCH_PART_ITEM_LIST_SUCCESS,
  PartItemActions,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultPartItem, defaultPartItemState } from "./defaultState";

export const partItemReducer = (
  state: IStoreState["partItem"] = defaultPartItemState,
  action: PartItemActions,
) => {
  switch (action.type) {
    case FETCH_PART_ITEM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PART_ITEM_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PART_ITEM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_PART_ITEM: {
      const newState = produce(state, (draftState) => {
        draftState.partItem.loading = LoadState.NotLoaded;
        draftState.partItem.data = defaultPartItem;
        draftState.partItem.error = null;
      });
      return newState;
    }

    case CLEAR_PART_ITEM_STATE: {
      return defaultPartItemState;
    }

    default: {
      return state;
    }
  }
};
