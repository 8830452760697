import produce from "immer";

import {
  CLEAR_DRAWING_ITEM,
  CLEAR_DRAWING_ITEM_STATE,
  DrawingItemActions,
  FETCH_DRAWING_ITEM_LIST_FAILED,
  FETCH_DRAWING_ITEM_LIST_PROGRESS,
  FETCH_DRAWING_ITEM_LIST_SUCCESS,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultDrawingItem, defaultDrawingItemState } from "./defaultState";

export const DrawingItemReducer = (
  state: IStoreState["drawingItem"] = defaultDrawingItemState,
  action: DrawingItemActions,
) => {
  switch (action.type) {
    case FETCH_DRAWING_ITEM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DRAWING_ITEM_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DRAWING_ITEM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_DRAWING_ITEM: {
      const newState = produce(state, (draftState) => {
        draftState.drawingItem.loading = LoadState.NotLoaded;
        draftState.drawingItem.data = defaultDrawingItem;
        draftState.drawingItem.error = null;
      });
      return newState;
    }

    case CLEAR_DRAWING_ITEM_STATE: {
      return defaultDrawingItemState;
    }

    default: {
      return state;
    }
  }
};
