import { LoadState } from "../../constants/enums";
import {
  IPurchaseRequisitionItem,
  IPurchaseRequisition,
  IPurchaseRequisitionState,
} from "./purchase-requisition.types";

export const defaultIPurchaseRequisitionItem: IPurchaseRequisitionItem = {
  id: 0,
  part_code: "",
  description: "",
  required_quantity: "",
  requested_date: "",
  supplier_name: "",
  supplier_uuid: "",
  po_no: "",
  min: 0,
  max: 0,
  stock: 0,
  comaprision_sheet: "",
  comment: "",
};

export const defaultPurchaseRequisition: IPurchaseRequisition = {
  purchase_requisition_uuid: null,
  pr_prefix: "RWSR",
  request_division: null,
  request_department: "MRP",
  request_reference_a: null,
  request_reference_b: null,
  sod_no: null,
  purchase_items: [defaultIPurchaseRequisitionItem],
  first_approval_by_uuid: null,
  first_approval_by_name: null,
  second_approval_by_uuid: null,
  second_approval_by_name: null,
  status: "ACTIVE",
};

export const defaultPurchaseRequisitionState: IPurchaseRequisitionState = {
  purchase_requisition_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_purchase_requisition: {
    data: defaultPurchaseRequisition,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
