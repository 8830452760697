export const getUserAuth = (): string | null => {
  const auth = localStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth).token;
  }
  return null;
};

export const getCurrentUserEmail = (): string | null => {
  const auth = localStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth).user.email;
  }
  return null;
};
