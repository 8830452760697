import { LoadState } from "../../constants/enums";
import { IVendor, IVendorState } from "./vendors.types";

export const defaultVendor: IVendor = {
  vendors_uuid: null,
  name_of_entity: "",
  vendor_email: "",
  nature_of_business: null,
  location: null,
  legal_status_of_party: null,
  type_of_service: null,
  gst_number: null,
  state: null,
  address: null,
  contact_person_name: null,
  contact_person_designation: null,
  contact_person_email: null,
  bank_holders_name: null,
  bank_name: null,
  branch_name: null,
  account_number: null,
  account_type: null,
  pan_number: null,
  ifsc_code: null,
  branch_code: null,
  bank_address: null,
  branch_location: null,
  branch_state: null,
  status: "ACTIVE",
};

export const defaultVendorState: IVendorState = {
  vendor_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_vendor: {
    data: defaultVendor,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
