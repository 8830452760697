import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import React from "react";
import { IStandardCardProps } from "./interfaces/ICardsProps";

export const StandardCard: React.FC<IStandardCardProps> = (props) => {
  const { heading, rightHeading, sx, variant = "normal" } = props;

  const isNode = React.isValidElement(rightHeading);

  return (
    <Card
      sx={{
        margin: 0,
        borderRadius: "20px",
        padding: variant === "normal" ? "20px" : "10px",
        boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
        ...sx,
      }}
    >
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
        {heading ? (
          <Typography variant="h2" fontWeight={"700"}>
            {heading}
          </Typography>
        ) : (
          <Box></Box>
        )}
        {isNode && <Box>{rightHeading}</Box>}
        {!isNode && (
          <Typography variant="body1" fontWeight={"400"}>
            {rightHeading}
          </Typography>
        )}
      </Box>
      {heading && <Divider sx={{ mt: 2, mb: 2 }} />}
      {props.children}
    </Card>
  );
};
