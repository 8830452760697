import { LoadState } from "../../constants/enums";
import {
  IPurchaseOrder,
  IPurchaseOrderItem,
  IPurchaseOrderState,
} from "./purchase-order.types";

export const defaultPurchaseOrderItem: IPurchaseOrderItem = {
  purchase_order_items_uuid: null,
  purchase_requisition_uuid: "",
  pr_no: "",
  part_code: null,
  description: null,
  hsn_code: null,
  etd: null,
  eta: null,
  quantity: null,
  uom: null,
  price_per_quantity: null,
  total_price: null,
  status: "ACTIVE | INACTIVE",
};

export const defaultPurchaseOrder: IPurchaseOrder = {
  purchase_order_uuid: null,
  purchase_order_no: "",
  vendor_name: "",
  vendor_uuid: "",
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_state: null,
  vendor_address_city: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  vendor_contact: null,
  vendor_gstin: null,
  purchase_order_date: null,
  pr_references: [],
  dispatch_mode: null,
  destination: null,
  terms_of_payment: null,
  terms_of_delivery: null,
  quotation_no: null,
  pan: null,
  gstin: null,
  deliver_address_line1: null,
  deliver_address_line2: null,
  deliver_address_state: null,
  deliver_address_city: null,
  deliver_address_pincode: null,
  deliver_address_country: null,
  deliver_contact: null,
  purchase_order_items: [defaultPurchaseOrderItem],
  remark: null,
  currency: null,
  discount: null,
  discount_value: null,
  frieght: null,
  frieght_value: null,
  packing_charges: null,
  packing_charges_value: null,
  others: null,
  others_value: null,
  others_two: null,
  others_two_value: null,
  full_amount: null,
  customs_duty: null,
  customs_duty_value: null,
  igst: null,
  igst_value: null,
  sgst: null,
  sgst_value: null,
  cgst: null,
  cgst_value: null,
  total_amount_after_tax: null,
  total_amount_in_words: null,
  first_approver_uuid: null,
  first_approver_name: null,
  second_approver_uuid: null,
  second_approver_name: null,
  status: "PO_REQUESTED",
};

export const defaultPurchaseOrderState: IPurchaseOrderState = {
  purchase_order_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_purchase_order: {
    data: defaultPurchaseOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
