import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_VENDORS_ROUTE } from "./Vendors.constants";

const VendorsListTable = Loadable(
  lazy(() =>
    import("./VendorsListTable").then(({ VendorsListTable }) => ({
      default: VendorsListTable,
    })),
  ),
);
const ManageSingleVendor = Loadable(
  lazy(() =>
    import("./ManageSingleVendor").then(({ ManageSingleVendor }) => ({
      default: ManageSingleVendor,
    })),
  ),
);

export const vendorsRoutes = [
  {
    path: APP_VENDORS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <VendorsListTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_VENDORS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleVendor />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_VENDORS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleVendor />
      </AuthorizedRoute>
    ),
  },
];
