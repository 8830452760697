import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { QUOTES_ROUTE } from "./Quote.constants";

const QuotesTable = Loadable(
  lazy(() =>
    import("./QuotesTable").then(({ QuotesTable }) => ({
      default: QuotesTable,
    })),
  ),
);
const ManageQuoteTabPanel = Loadable(
  lazy(() =>
    import("./ManageQuoteTabPanel").then(({ ManageQuoteTabPanel }) => ({
      default: ManageQuoteTabPanel,
    })),
  ),
);

export const quotesRoutes = [
  {
    path: QUOTES_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <QuotesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${QUOTES_ROUTE}/manage/:enquiryNumber/:uuid/:revision`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageQuoteTabPanel />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${QUOTES_ROUTE}/manage/:enquiryNumber/:uuid/:revision/:tab`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageQuoteTabPanel />
      </AuthorizedRoute>
    ),
  },
];
