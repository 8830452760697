import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultDrawingUploadState } from "./drawing-uploads.state";
import {
  CLEAR_DRAWING_UPLOAD,
  CLEAR_DRAWING_UPLOAD_STATE,
  DrawingActions,
  FETCH_DRAWING_UPLOAD_FAILED,
  FETCH_DRAWING_UPLOAD_LIST_FAILED,
  FETCH_DRAWING_UPLOAD_LIST_PROGRESS,
  FETCH_DRAWING_UPLOAD_LIST_SUCCESS,
  FETCH_DRAWING_UPLOAD_PROGRESS,
  FETCH_DRAWING_UPLOAD_SUCCESS,
} from ".";

export const drawingUploadsReducer = (
  state: IStoreState["drawingUploads"] = defaultDrawingUploadState,
  action: DrawingActions,
) => {
  switch (action.type) {
    case FETCH_DRAWING_UPLOAD_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.drawing_upload_list.loading = LoadState.InProgress;
        draftState.drawing_upload_list.data = [];
        draftState.drawing_upload_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DRAWING_UPLOAD_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.drawing_upload_list.loading = LoadState.Loaded;
        draftState.drawing_upload_list.data = data;
        draftState.drawing_upload_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DRAWING_UPLOAD_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.drawing_upload_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_DRAWING_UPLOAD_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_drawing_upload.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DRAWING_UPLOAD_SUCCESS: {
      const { data } = action.payload;
      console.log(data);
      const newState = produce(state, (draftState) => {
        draftState.single_drawing_upload.loading = LoadState.Loaded;
        draftState.single_drawing_upload.data = data;
      });
      return newState;
    }
    case FETCH_DRAWING_UPLOAD_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_drawing_upload.loading = LoadState.Failed;
        draftState.single_drawing_upload.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_DRAWING_UPLOAD: {
      const newState = produce(state, (draftState) => {
        draftState.single_drawing_upload.loading = LoadState.NotLoaded;
        draftState.single_drawing_upload.data =
          defaultDrawingUploadState["single_drawing_upload"]["data"];
        draftState.single_drawing_upload.error = null;
      });
      return newState;
    }

    case CLEAR_DRAWING_UPLOAD_STATE: {
      return defaultDrawingUploadState;
    }

    default: {
      return state;
    }
  }
};
