import { LoadState } from "../../constants/enums";
import {
  IMasterBom,
  IMasterBomProduct,
  IMasterBomState,
} from "./masterBom.types";

export const defaultMasterProduct: IMasterBomProduct = {
  bom_item_uuid: null,
  level: "",
  part_code: "",
  drawing_uuid: "",
  drawing_name: "",
  drawing_no: "",
  part_name: "",
  product_h: 0,
  product_w: 0,
  product_d: 0,
  meaning: "",
  item_type: "",
  quantity_per_unit: 0,
  unit: "",
};
export const defaultMasterBom: IMasterBom = {
  master_bom_uuid: "",
  product_code: "",
  product_size: null,
  series: null,
  rev_no: null,
  effective_date: null,
  description: null,
  add: null,
  change: null,
  delete: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  excel_link: null,
  approval_status: null,
  product_items: [defaultMasterProduct],
  status: "MASTER_BOM_REQUESTED",
};

export const defaultMasterBomState: IMasterBomState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  masterBom: {
    data: defaultMasterBom,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approvalMasterBomList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
