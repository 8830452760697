import produce from "immer";
import {
  CLEAR_VENDOR,
  CLEAR_VENDOR_STATE,
  FETCH_VENDOR_FAILED,
  FETCH_VENDOR_LIST_FAILED,
  FETCH_VENDOR_LIST_PROGRESS,
  FETCH_VENDOR_LIST_SUCCESS,
  FETCH_VENDOR_PROGRESS,
  FETCH_VENDOR_SUCCESS,
} from "./vendors.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultVendor, defaultVendorState } from "./vendors.state";
import { VendorsActions } from ".";

export const vendorsReducer = (
  state: IStoreState["vendors"] = defaultVendorState,
  action: VendorsActions,
) => {
  switch (action.type) {
    case FETCH_VENDOR_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.vendor_list.loading = LoadState.InProgress;
        draftState.vendor_list.data = [];
        draftState.vendor_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_VENDOR_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vendor_list.loading = LoadState.Loaded;
        draftState.vendor_list.data = data;
        draftState.vendor_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.vendor_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_VENDOR_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_vendor.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_VENDOR_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_vendor.loading = LoadState.Loaded;
        draftState.single_vendor.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_vendor.loading = LoadState.Failed;
        draftState.single_vendor.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_VENDOR: {
      const newState = produce(state, (draftState) => {
        draftState.single_vendor.loading = LoadState.NotLoaded;
        draftState.single_vendor.data = defaultVendor;
        draftState.single_vendor.error = null;
      });
      return newState;
    }

    case CLEAR_VENDOR_STATE: {
      return defaultVendorState;
    }

    default: {
      return state;
    }
  }
};
