import { LoadState } from "../../constants/enums";
import {
  IMrpAllocation,
  IMrpAllocationItem,
  IMrpAllocationsState,
} from "./mrp-allocations.types";

export const defaultMRPAllocationItem: IMrpAllocationItem = {
  serial_no: "",
  unit: "",
  level: "",
  meaning: "",
  item_type: "",
  part_code: "",
  part_name: "",
  product_d: 0,
  product_h: 0,
  product_w: 0,
  drawing_no: "",
  drawing_name: "",
  drawing_uuid: "",
  bom_item_uuid: null,
  quantity_per_unit: 0,
  stock: 0,
  remark: "",
  origin: 0,
  allocated: 0,
  transit: 0,
};

export const defaultMRPAllocation: IMrpAllocation = {
  mrp_allocation_uuid: null,
  order_no: "",
  bom_uuid: null,
  product_code: null,
  product_size: null,
  series: null,
  rev_no: null,
  effective_date: null,
  description: null,
  add: null,
  change: null,
  delete: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  excel_link: null,
  product_items: [],
  status: "MRP_ALLOCATION_REQUESTED",
};

export const defaultMRPAllocationState: IMrpAllocationsState = {
  mrp_allocations_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_mrp_allocation: {
    data: defaultMRPAllocation,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
