import { action } from "typesafe-actions";
import { ISecurityRoleGroup } from "./role-group.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";

export const FETCH_SECURITY_ROLES_GROUP_LIST_PROGRESS =
  "FETCH_SECURITY_ROLES_GROUP_LIST_PROGRESS";
export const FETCH_SECURITY_ROLES_GROUP_LIST_SUCCESS =
  "FETCH_SECURITY_ROLES_GROUP_LIST_SUCCESS";
export const FETCH_SECURITY_ROLES_GROUP_LIST_FAILED =
  "FETCH_SECURITY_ROLES_GROUP_LIST_FAILED";

export const fetchSecurityRoleGroupListProgress = () =>
  action(FETCH_SECURITY_ROLES_GROUP_LIST_PROGRESS);
export const fetchSecurityRoleGroupListSuccess = (
  list: ISecurityRoleGroup[],
  totalRecords: number,
) => action(FETCH_SECURITY_ROLES_GROUP_LIST_SUCCESS, { list, totalRecords });
export const fetchSecurityRoleGroupListFailed = () =>
  action(FETCH_SECURITY_ROLES_GROUP_LIST_FAILED);

export const fetchSecurityRoleGroupListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSecurityRoleGroupListProgress());

      const res = await api.get(
        `/security/get-role-groups?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      );
      const data: ISecurityRoleGroup[] = res.data.data;
      dispatch(fetchSecurityRoleGroupListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchSecurityRoleGroupListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSecurityRoleGroupAsync =
  (
    data: ISecurityRoleGroup,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post("/security/upsert-role-group", payload);
      onCallback(true);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Role Group saved successfully!",
          type: "success",
        }),
      );
      dispatch(fetchSecurityRoleGroupListAsync(1, 50));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SECURITY_ROLE_GROUP_LIST = "CLEAR_SECURITY_ROLE_GROUP_LIST";
export const clearSecurityRoleGroupListAsync = () =>
  action(CLEAR_SECURITY_ROLE_GROUP_LIST);
