import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  APP_ROUTING_APPROVALS_ROUTE,
  APP_ROUTING_ROUTE,
} from "./Routing.constants";

const RoutingList = Loadable(
  lazy(() =>
    import("./RoutingList").then(({ RoutingList }) => ({
      default: RoutingList,
    })),
  ),
);
const ManageRouting = Loadable(
  lazy(() =>
    import("./ManageRouting").then(({ ManageRouting }) => ({
      default: ManageRouting,
    })),
  ),
);

const RoutingApprovalListTable = Loadable(
  lazy(() =>
    import("./Approvals/RoutingApprovalListTable").then(
      ({ RoutingApprovalListTable }) => ({
        default: RoutingApprovalListTable,
      }),
    ),
  ),
);

export const routingModuleRoutes = [
  {
    path: APP_ROUTING_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ROUTING]}>
        <RoutingList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_ROUTING_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ROUTING]}>
        <ManageRouting />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_ROUTING_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ROUTING]}>
        <ManageRouting />
      </AuthorizedRoute>
    ),
  },
  {
    path: APP_ROUTING_APPROVALS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_ROUTING]}>
        <RoutingApprovalListTable />
      </AuthorizedRoute>
    ),
  },
];
