import produce from "immer";

import {
  CLEAR_MASTER_BOM,
  CLEAR_MASTER_BOM_STATE,
  defaultMasterBom,
  defaultMasterBomState,
  FETCH_MASTER_BOM_FAILED,
  FETCH_MASTER_BOM_LIST_FAILED,
  FETCH_MASTER_BOM_LIST_PROGRESS,
  FETCH_MASTER_BOM_LIST_SUCCESS,
  FETCH_MASTER_BOM_PROGRESS,
  FETCH_MASTER_BOM_SUCCESS,
  MasterBomActions,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

export const masterBomReducer = (
  state: IStoreState["masterBom"] = defaultMasterBomState,
  action: MasterBomActions,
) => {
  switch (action.type) {
    case FETCH_MASTER_BOM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_MASTER_BOM_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_MASTER_BOM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_MASTER_BOM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.masterBom.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MASTER_BOM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.masterBom.loading = LoadState.Loaded;
        draftState.masterBom.data = data;
      });
      return newState;
    }
    case FETCH_MASTER_BOM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.masterBom.loading = LoadState.Failed;
        draftState.masterBom.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_MASTER_BOM: {
      const newState = produce(state, (draftState) => {
        draftState.masterBom.loading = LoadState.NotLoaded;
        draftState.masterBom.data = defaultMasterBom;
        draftState.masterBom.error = null;
      });
      return newState;
    }

    case CLEAR_MASTER_BOM_STATE: {
      return defaultMasterBomState;
    }

    default: {
      return state;
    }
  }
};
